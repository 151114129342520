import React from 'react'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import { useIntl } from 'gatsby-plugin-intl'
import SeniorSoftwareArchitect from '../../../components/VacancyPage/SeniorSoftwareArchitectPage'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'
import SEO from '../../../components/seo'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleSeniorArchitect)}
        description={formatMessage(metaDescription.seniorArchitectDescription)}
        lang={locale}
      />
      <SeniorSoftwareArchitect />
    </Layout>
  )
}

export default IndexPage
