import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Senior Software Architect',
  introTitle: 'Senior Software Architect (m/w/d) - Software Development in full time',
  introInfo: '<p>freshcells systems engineering GmbH is a software company based in Düsseldorf, Germany with a dynamic team of over 40 employees.</p><p>We are not a hire-and-fire company; we prefer to quench our thirst with a cold drink after work.</p><p>Among other vacancies, we are looking for a Senior Software Architect (full-time software development) to support our team in Düsseldorf.</p><p>freshcells is always on the lookout for the latest software trends and principles. To run with the most modern software stacks is part of our DNA. Docker, Kubernetes, React, NodeJS … freshcells has been an early adopter and contributor in all fields. If you feel at home in such an environment and enjoy working with talented co-workers from all over the world who challenge you and like to be challenged by you, you are the right fit for us.</p>',
  responsibilitiesTitle: 'Your Responsibilities',
  responsibility1: 'Development of modern backend applications based on Node.Js',
  responsibility2: 'Development of modern frontend applications based on React JS',
  responsibility3: 'Utilization of current technologies such as Kubernetes and GraphQL',
  responsibility4: 'Design and implementation of new features for sophisticated software solutions in cooperation with project management, design, and development departments',
  responsibility5: 'Deployment of web applications and creation of unit tests',
  responsibility6: 'Participation in continuous improvement processes',
  qualificationsTitle: 'Your Qualifications',
  qualification1: 'You can quickly adapt to new technologies, understand trends, and know how to make long term technical decisions',
  qualification2: 'Very good knowledge in designing and developing sophisticated enterprise backend systems, ideally based on Node.JS',
  qualification3: 'Strong knowledge of ES 6 JavaScript as well as extensive knowledge of frameworks and libraries such as reactJS, vue.js, and/or Angular',
  qualification4: 'Experience of PHP, ideally with frameworks such as Symfony, Laravel or Yii',
  qualification5: 'Sound experience with relational and non-relational databases',
  qualification6: 'Good knowledge of data structure design',
  qualification7: 'Competences in consuming and creating web services based on ReST and especially GraphQL',
  qualification8: 'Know-how in performance optimization',
  qualification9: 'Basic knowledge of HTML and CSS is helpful',
  qualification10: 'Experience with agile development in a team',
  qualification11: 'Hands-on experience with automated software testing',
  qualification12: 'Structured and solution-oriented working style for sustainable and scalable web systems',
  qualification13: 'Fluent in English',
  nicetohaveTitle: 'Nice to have',
  nicetohave1: 'Experience with containerization technologies such as Docker etc.',
  nicetohave2: 'Experience with container orchestration tools like, i.a., Kubernetes, OpenShift, or Docker Swarm',
  nicetohave3: 'Knowledge of designing and adapting software to be twelve factor applications',
  nicetohave4: 'Knowledge of continuous integration via tools like Jenkins'
})
